.app {
  margin: 20vh 5vw;
}

.header{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.header a {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    align-self: baseline;
    font-size: 1rem;
    text-decoration:none;
    margin-bottom: 10vh;
    cursor: pointer;
}



img{
   width: 15vw;
   max-height: 15vw;
}